.carousel {
  max-width: 1100px;
  margin: 20px auto;

  img {
    width: 400px;
  }

  p {
    padding: 20px 0px;
    font-size: 35px;
    font-style: italic;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.9px;
    color: $violet;
  }

  .slider-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .slider {
      position: relative;
      height: 500px;
      width: 350px;
      overflow: hidden;

      @media only screen and (max-width: 375px) {
        height: 400px;
        width: 300px;
      }
    }
  }

  .carousel-indicators {
    height: 15px;
    width: 75px;
    position: relative;
    margin: 0 auto;
  }

  .bullet {
    border-radius: 7.5px;
    width: 15px;
    height: 15px;
    background-color: lightgray;
    position: absolute;
    top: 0px;
    transition: background-color 0.3s ease;

    &.active {
      background-color: #85d0ce;
      box-shadow: 0 0 8.5px #85d0ce;
    }
  }

  .carousel-inner {
    padding-bottom: 45px;

    &:hover {
      cursor: grab;
    }

    &:active {
      cursor: grabbing;
    }
  }

  .content {
    text-align: left;
  }
}
