.content-wrapper {
  .container {
    max-width: 1200px;
    padding: 70px 30px;

    @include media-breakpoint-down(lg) {
      padding: 50px 30px;
    }

    @include media-breakpoint-down(md) {
      padding: 40px 20px;
    }
  }

  // Hero section
  .hero {
    .image-wrapper {
      max-width: 650px !important;
      top: 5%;
      right: 10%;
      position: absolute;
      z-index: 3;
    }

    @media screen and (max-width: 1600px) {
      .image-wrapper {
        max-width: 600px !important;
        top: 8%;
        right: 10%;
      }
    }

    @media screen and (max-width: 1470px) {
      .image-wrapper {
        top: 8%;
        right: 5%;
      }
    }

    @media screen and (max-width: 1280px) {
      .image-wrapper {
        top: 8%;
        right: 2%;
      }
    }

    @media screen and (max-width: 1240px) {
      .image-wrapper {
        max-width: 550px !important;
        top: 15%;
        right: 2%;
      }
    }

    @media screen and (max-width: 1320px) {
      .image-wrapper {
        max-width: 550px !important;
        top: 15%;
        right: 2%;
      }
    }

    @media screen and (max-width: 1180px) {
      .image-wrapper {
        top: 15%;
        right: 1%;
      }
    }

    @media screen and (max-width: 1130px) {
      .image-wrapper {
        max-width: 450px !important;
        top: 12%;
        right: 1%;
      }
    }
  }

  .banner {
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-down(sm) {
      h1 {
        font-size: 28px;
      }
    }

    .banner-image {
      position: absolute;
      max-height: 300px;
    }
  }

  .info {
    .lead {
      padding: 0 220px;

      @include media-breakpoint-down(lg) {
        padding: 0 5px;
      }
    }
    .label {
      font-size: 25px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      letter-spacing: -0.5px;
      color: $pink;

      @include media-breakpoint-down(lg) {
        font-size: 18px;
      }
    }

    .infocard-wrapper {
      justify-content: space-between;
      display: flex;
      flex-wrap: wrap;
    }

    @media screen and (max-width: 1140px) {
      .infocard-wrapper {
        justify-content: center;
      }

      .card {
        margin: 20px !important;
      }
    }

    .card {
      width: 340px;
      height: 465px;
      margin: 20px 5px;

      @include media-breakpoint-down(sm) {
        margin: 10px !important;
        height: 440px;
      }
    }
  }

  .how-it-works {
    .testimonials {
      max-width: 900px;
      margin: 20px auto;
      img {
        height: 350px;
        padding: 10px;

        @include media-breakpoint-down(lg) {
          height: 250px;
        }
      }
      p {
        font-style: italic;
        color: $violet;
        font-size: 26px;

        @include media-breakpoint-down(lg) {
          font-size: 20px;
        }
      }

      .text-small {
        font-size: 18px;
        color: $grey;
        font-style: normal;

        @include media-breakpoint-down(lg) {
          font-size: 16px;
        }
      }
    }
  }

  .pricing {
    .symbols {
      font-size: 55px;
      color: white;
      padding: 0px 35px;

      @include media-breakpoint-down(md) {
        font-size: 35px;
        padding: 0 12px;
      }
    }

    .info {
      border-radius: 15px;
      color: #2c282e;
      height: 75px;
      width: 100%;
      font-size: 25px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      letter-spacing: -0.5px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 10px;

      @include media-breakpoint-down(lg) {
        font-size: 20px;
      }

      @include media-breakpoint-down(md) {
        border-radius: 10px;
        font-size: 14px;
        height: 55px;
        width: 140px;
      }

      &.result {
        width: 100%;

        @include media-breakpoint-down(md) {
          width: 280px;
        }
      }
    }

    h2 {
      color: white;
    }

    .form-wrap {
      width: 400px;
      margin: 2rem auto;

      @include media-breakpoint-down(md) {
        width: 300px;
        margin: 2rem auto;
      }
    }

    .price-cardwrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin-top: 130px;
    }

    .card {
      width: 390px;
      height: 410px;
      border-radius: 20px;
      background: #fff;
      padding: 30px;
      margin: 15px;
      display: flex;
      justify-content: space-between;
    }

    .price-label {
      font-size: 25px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      letter-spacing: -0.5px;
      color: $grey;
    }

    .product-info {
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px;
      letter-spacing: -0.44px;

      .td-check {
        vertical-align: middle;
        width: 35px;

        img {
          padding-bottom: 3px;
        }
      }
    }

    .btn-price {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.32px;
      color: white;
      height: 58px;
      border: none;
    }
  }

  .blog-section {
    .posts-wrapper {
      justify-content: space-between;
      display: flex;
      flex-wrap: wrap;
    }

    @media screen and (max-width: 1170px) {
      .posts-wrapper {
        justify-content: center;
      }

      .card {
        margin: 12px !important;
      }
    }

    .link {
      text-decoration: none;

      & h5:hover {
        color: $pink;
      }
    }

    .card {
      width: 360px;
      height: 520px;
      margin: 15px 0px;
      border-radius: 10px;
      border: 0px;
      filter: drop-shadow(1px 1px 17px rgba(0, 0, 0, 0.17));

      @include media-breakpoint-down(sm) {
        width: 350px;
        height: 520px;
      }

      .card-img-top {
        border-radius: 10px 10px 0px 0px;
      }

      .card-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      h5 {
        color: $grey;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.44px;
      }

      p {
        color: $grey;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.32px;
      }

      .link-blog {
        color: $grey;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.32px;
        text-decoration-line: underline;

        &:hover {
          color: $pink;
        }
      }
    }
  }
}
