.sidebar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: $violet;
  height: '100vh';
  position: fixed;

  ul,
  li {
    margin: 0;
    padding: 0;
  }

  ul {
    padding: 25px;
    position: absolute;
    top: 100px;
    width: 230px;
  }

  li {
    list-style: none;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.32px;
    text-decoration: none;
    color: white;
    padding-left: 20px;

    a {
      text-decoration: none;
      &:hover {
        color: $mint;
      }
    }

    &:hover {
      color: $mint;
    }
  }

  .btn-sidebar {
    color: $pink;
    border-radius: 50px;
    border: 2px solid $pink;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.32px;
    height: 44px;
    padding: 6px 25px;

    &:hover,
    :active,
    :focus {
      background-color: transparent;
      border: 2px solid $pink;
      color: $pink;
    }
  }
}
