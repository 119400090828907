.modal {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 2rem;
  border-radius: 0px;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 12;

  @include media-breakpoint-down(sm) {
    padding: 10px;
  }

  .modal-content {
    position: relative;
    border-radius: 0;

    &.image {
      background-image: url('/assets/images/popup-bg.png');
      background-size: cover;
      background-repeat: no-repeat;

      @include media-breakpoint-down(sm) {
        background: linear-gradient(90deg, rgba(182, 221, 225, 1) 0%, rgba(152, 199, 205, 1) 83%);
      }
    }

    &.transparent {
      background: transparent;
    }

    &.white {
      background: white;
    }
  }

  .close-btn {
    position: absolute;
    top: -30px;
    right: -30px;
    cursor: pointer;
    z-index: 15;

    &:hover,
    :active,
    :focus {
      path {
        fill: $pink;
      }
    }
  }
}
