.user {
  max-width: 1200px;
  padding: 40px 20px;
  margin: 0 auto;

  @include media-breakpoint-down(sm) {
    padding: 10px;
  }

  table,
  tr,
  th,
  td {
    background: transparent !important;
    border-color: rgba(65, 64, 66, 0.5);

    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }
  }

  thead,
  th {
    color: $violet;
  }
}
