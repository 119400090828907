.blog {
  .posts-wrapper {
    max-width: 1200px;
    margin: 0 auto;

    @include media-breakpoint-down(xl) {
      max-width: 800px;
    }
  }

  .link {
    text-decoration: none;

    & h5:hover {
      color: $pink;
    }
  }

  .card {
    width: 380px;
    height: 480px;
    margin: 10px auto;
    border-radius: 10px;
    border: 0px;
    filter: drop-shadow(1px 1px 17px rgba(0, 0, 0, 0.17));

    .card-img-top {
      border-radius: 10px 10px 0px 0px;
    }

    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    h5 {
      color: $grey;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.44px;
    }

    p {
      color: $grey;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.32px;
    }

    .link-blog {
      color: $grey;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.32px;
      text-decoration-line: underline;

      &:hover {
        color: $pink;
      }
    }
  }

  @media screen and (max-width: 830px) {
    .card {
      width: 350px !important;
    }
  }
}
