.header {
  background-color: rgba(40, 40, 113, 0.2);
  .navbar {
    width: 100%;
    z-index: $zindex-sticky;

    .brand-logo {
      width: 150px;
      @media (max-width: 500px) {
        width: 125px;
      }
    }

    ul {
      margin: 0;
      padding: 0;
    }

    li {
      white-space: nowrap;
      list-style: none;
    }

    .nav-link {
      cursor: pointer;
      padding: 8px 25px;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.32px;
      text-decoration: none;
      color: $grey;

      &:hover {
        color: $pink;
      }
    }

    .btn-navbar {
      color: $violet;
      border-radius: 28px;
      border: 2px solid $violet;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.32px;
      height: 56px;
      padding: 6px 30px;

      &:hover,
      :active,
      :focus {
        background-color: transparent;
        border: 2px solid $pink;
        color: $pink;
      }
    }
  }

  .toggle-button {
    display: none;
    outline: none;
    border: none;
    -webkit-user-select: none;
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
    width: 50px;
    height: 50px;
    background: transparent;
    z-index: 20;

    .toggle-button-path-opened {
      stroke: $pink;
    }

    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  .navbar-items {
    display: block;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}
