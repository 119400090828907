.form {
  input {
    font-weight: 500;
    font-size: 14px;
    color: $grey;
    line-height: normal;
    letter-spacing: -0.32px;
  }

  .form-group {
    margin: 0.7rem 0;
    position: relative;
  }

  .form-control {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .form-control::placeholder {
    color: #58595b;
    opacity: 1;
  }

  .form-check-label {
    font-size: 9px;
    line-height: 12px;
  }

  h1 {
    font-size: 34px;
  }

  h2 {
    font-size: 34px;
    color: white;
  }

  p {
    font-size: 18px;
  }

  .text-sm {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: -0.28px;
  }
}
