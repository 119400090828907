.post {
  .post-wrapper {
    max-width: 700px;
    margin: 0px auto;

    .image-container {
      position: relative;

      img {
        border-radius: 20px;
      }
    }

    .overlay {
      border-radius: 20px;
      padding: 60px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(56, 52, 59, 0.45);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .path {
      color: #208380;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 25px;

      .back-link {
        text-decoration: none;
        color: #208380;

        &:hover {
          color: #85d0ce;
        }
      }
    }

    .blog-content {
      img {
        border-radius: 20px;
        margin: 20px 0;
      }

      h1 {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px;
        color: $grey;
      }

      h2 {
        font-size: 18px;
        line-height: 23px;
        font-weight: 700;
        color: $grey;
      }

      h3 {
        font-size: 16px;
        line-height: 21px;
        font-weight: 700;
        color: $grey;
      }

      p {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
        color: $grey;
      }
    }
  }

  .read-also {
    .posts-wrapper {
      justify-content: center;
      display: flex;
      flex-wrap: wrap;
    }

    .link {
      text-decoration: none;

      & h5:hover {
        color: $pink;
      }
    }

    .card {
      width: 380px;
      height: 500px;
      margin: 12px;
      border-radius: 10px;
      border: 0px;
      filter: drop-shadow(1px 1px 17px rgba(0, 0, 0, 0.17));

      @include media-breakpoint-down(md) {
        width: 350px;
      }

      .card-img-top {
        border-radius: 10px 10px 0px 0px;
      }

      .card-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      h5 {
        color: $grey;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.44px;
      }

      p {
        color: $grey;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.32px;
      }

      .link-blog {
        color: $grey;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.32px;
        text-decoration-line: underline;

        &:hover {
          color: $pink;
        }
      }
    }
  }
}
