.footer {
  p,
  li {
    color: #cacaca;
    font-size: 16px;
    line-height: 28px;
  }

  .nav-link {
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: #85d0ce;
    }
  }

  a {
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
      color: #85d0ce;
    }
  }

  .widget img {
    vertical-align: middle;
    height: 23px;
  }

  .widget-title {
    font-weight: 900;
    word-spacing: 0.1rem;
    letter-spacing: -0.01rem;
  }

  .widget .list-unstyled:not(.tag-list) li + li {
    margin-top: 0.35rem;
  }

  .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    &.social {
      padding-top: 10px;
      a {
        padding-right: 10px;
      }
    }
  }

  .social a:not(.btn-circle) {
    font-size: 1rem;
    transition: all 0.2s ease-in-out;
    transform: translateY(0);
  }

  .social > a {
    margin: 0 0.7rem 0 0;
  }

  .list-unstyled {
    padding-left: 0;
    list-style: none;
  }
}
