// icons
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css');

// fonts
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900&display=swap');

//body
body {
  box-sizing: border-box;
  font-family: 'Nunito', sans-serif;
  margin: 0 auto;
  padding: 0;
  height: 100%;
  position: relative;
}

html,
#root {
  color: $grey;
  scroll-behavior: smooth;
  overflow-x: hidden;
  height: 100%;
  position: relative;
}

h1 {
  font-size: 45px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.9px;
  color: $violet;

  @include media-breakpoint-down(md) {
    font-size: 30px;
    line-height: 35px;
  }
}

h2 {
  font-size: 35px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.9px;
  color: $violet;

  @include media-breakpoint-down(md) {
    font-size: 28px;
    line-height: 35px;
  }
}

h3 {
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.68px;
  color: $violet;

  @include media-breakpoint-down(md) {
    font-size: 20px;
    line-height: 30px;
  }
}

h5 {
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.44px;

  @include media-breakpoint-down(md) {
    font-size: 18px;
    line-height: 22px;
  }
}

p {
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.22px;

  @include media-breakpoint-down(md) {
    font-size: 16px;
    line-height: 20px;
  }
}

.fw-bold {
  font-weight: 900 !important;
}

.bg-gradient-violet-50 {
  background: $violet-gradient-light;
}

.bg-gradient-violet {
  background: $violet-gradient;
}

.bg-gradient-pink {
  background: linear-gradient(48deg, #d71b5a 21.16%, #a8184d 100%);
}

.bg-gradient-mint {
  background: linear-gradient(170deg, #208380 10%, #86d0ce 100%);
}

.bg-gradient-yellow {
  background: linear-gradient(48deg, #f5ae2b 34.9%, #cf9332 100%);
}

.bg-dark {
  background-color: #21262c;
}

.gradient-pink {
  background: linear-gradient(48deg, #d71b5a 21.16%, #a8184d 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.gradient-mint {
  background: linear-gradient(170deg, #208380 10%, #86d0ce 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.gradient-yellow {
  background: linear-gradient(48deg, #f5ae2b 34.9%, #cf9332 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.btn {
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  height: 50px;
  padding: 6px 50px !important;
}

.btn-violet {
  color: white;
}

.btn-outlined {
  color: $violet;
  border: 1px solid $violet;

  &:hover,
  :active,
  :focus {
    background-color: $violet;
    border: 2px solid $violet;
    color: white;
  }
}

.card-body {
  p {
    color: $grey;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.32px;
  }
}
